
<template>
    <div style="position:relative;z-index: 1;">
    <div class="box-emprty">
        <div class="emptyBox">
                <img class="emimg" :src="emptyImg"/>
                <span class="tip-text">当前科室暂无预约数据！</span>
                <!-- <span class="tip-text">零动医疗科技有限公司相关人员。</span> -->
        </div>
    </div>
</div>
</template>

<script>

export default {
    components:{},
    data(){
        return{
            emptyImg:'https://sz.drbodyhealth.com/emptybox.png',
        }
    },
    created(){
        if(this.$route.query){
           
        }
        
    },
  
    methods:{
  
    
    }
}
</script>

<style scoped lang="stylus">
.box-emprty
    width: 100%
    height: 100%
    display: flex
    flex-direction: columen
    align-items: center
    .emptyBox
        width:310px 
        height:300px  
        margin:140px auto
        text-align: center
        .emimg
            width: 260px 
            height: 250px 
        .tip-text
            display: block
            font-size:16px 
            color:#333333

</style>