<!-- 康复科--- 运动康复 -->
<template>
    <div class="box-recove">
        <div class="box-top">
            <div class="box-top-left">
                <div :class="(lastWeek) ? 'active-box-active' : 'active-box'" @click="activel">上一周</div>
                <div :class="(nowWeek) ? 'active-box-active' : 'active-box'" @click="activeno">本周</div>
                <div :class="(nextWeek) ? 'active-box-active' : 'active-box'" @click="activen">下一周</div>
            </div>
            <div class="box-top-right">
                <div class="active-box" v-if="isEdit" @click="xiugaiN">确认修改</div>
                <div class="active-box" v-if="!isEdit" @click="xiugaiC">修改</div>
            </div>
        </div>
        <!-- table-box 非空-->
        <div class="table-box" v-if="!isEmperty">
            <!-- 非编辑表格 -->
                <!-- 一个box里装两个表格 -->
                <div style="display:flex">
                    <!-- w1 -->
                    <el-table
                        v-if="((!isEdit)&&((data1!=null)&&(tableTopList!=null)))"
                        :data='data1'
                        style="width: 50%"
                        :cell-class-name="cellStyle"
                        :header-cell-class-name="tableHeaderStyle">
                        <el-table-column key="10"  prop="workTimeStart" label="开始时间" align="center" width="100"></el-table-column>
                        <el-table-column key="11" prop="workTimeEnd" label="结束时间" align="center" width="100"></el-table-column> 
                        <el-table-column key="12"  align="center" :label="tableTopList[0].date+'('+tableTopList[0].week+')'">
                                <el-table-column  key="13" align="center" label="总号量" >
                                    <template slot-scope="scope">
                                        {{scope.row.maxNum}}
                                    </template>
                                </el-table-column>
                                <el-table-column key="14" align="center"  label="已预约" >
                                    <template slot-scope="scope">{{scope.row.num}}</template> 
                                </el-table-column>
                        </el-table-column>
                    </el-table>
                    <!-- w2 -->
                    <el-table
                        v-if="((!isEdit)&&((data2!=null)&&(tableTopList!=null)))"
                        :data='data2'
                        style="width: 50%"
                        :cell-class-name="cellStyle"
                        :header-cell-class-name="tableHeaderStyle">
                        <el-table-column key="20"  prop="workTimeStart" label="开始时间" align="center" width="100"></el-table-column>
                        <el-table-column key="21" prop="workTimeEnd" label="结束时间" align="center" width="100"></el-table-column> 
                        <el-table-column key="22" align="center" :label="tableTopList[1].date+'('+tableTopList[1].week+')'">
                                <el-table-column key="23" align="center" label="总号量" >
                                    <template slot-scope="scope">
                                        {{scope.row.maxNum}}
                                    </template>
                                </el-table-column>
                                <el-table-column key="24" align="center"  label="已预约" >
                                    <template slot-scope="scope">{{scope.row.num}}</template> 
                                </el-table-column>
                        </el-table-column>
                    </el-table>
                </div>
                <div style="display:flex">
                    <!-- w3 -->
                    <el-table
                        v-if="((!isEdit)&&((data3!=null)&&(tableTopList!=null)))"
                        :data='data3'
                        style="width: 50%"
                        :cell-class-name="cellStyle"
                        :header-cell-class-name="tableHeaderStyle">
                        <el-table-column key="30"  prop="workTimeStart" label="开始时间" align="center" width="100"></el-table-column>
                        <el-table-column key="31" prop="workTimeEnd" label="结束时间" align="center" width="100"></el-table-column> 
                        <el-table-column key="32" align="center"  :label="tableTopList[2].date+'('+tableTopList[2].week+')'">
                                <el-table-column  key="33" align="center" label="总号量" >
                                    <template slot-scope="scope">
                                        {{scope.row.maxNum}}
                                    </template>
                                </el-table-column>
                                <el-table-column key="34" align="center"  label="已预约" >
                                    <template slot-scope="scope">{{scope.row.num}}</template> 
                                </el-table-column>
                        </el-table-column>
                    </el-table>
                    <!-- w4 -->
                    <el-table
                        v-if="((!isEdit)&&((data4!=null)&&(tableTopList!=null)))"
                        :data='data3'
                        style="width: 50%"
                        :cell-class-name="cellStyle"
                        :header-cell-class-name="tableHeaderStyle">
                        <el-table-column key="40"  prop="workTimeStart" label="开始时间" align="center" width="100"></el-table-column>
                        <el-table-column key="41" prop="workTimeEnd" label="结束时间" align="center" width="100"></el-table-column> 
                        <el-table-column key="42" align="center" :label="tableTopList[3].date+'('+tableTopList[3].week+')'">
                                <el-table-column key="43" align="center" label="总号量" >
                                    <template slot-scope="scope">
                                        {{scope.row.maxNum}}
                                    </template>
                                </el-table-column>
                                <el-table-column key="44" align="center"  label="已预约" >
                                    <template slot-scope="scope">{{scope.row.num}}</template> 
                                </el-table-column>
                        </el-table-column>
                    </el-table>
                </div>
                <div style="display:flex">
                <!-- w5 -->
                <el-table
                    v-if="((!isEdit)&&((data5!=null)&&(tableTopList!=null)))"
                    :data='data5'
                    style="width: 50%"
                    :cell-class-name="cellStyle"
                    :header-cell-class-name="tableHeaderStyle">
                    <el-table-column key="50"  prop="workTimeStart" label="开始时间" align="center" width="100"></el-table-column>
                    <el-table-column key="51" prop="workTimeEnd" label="结束时间" align="center" width="100"></el-table-column> 
                    <el-table-column key="54" align="center" :label="tableTopList[4].date+'('+tableTopList[4].week+')'">
                            <el-table-column key="52" align="center" label="总号量" >
                                <template slot-scope="scope">
                                    {{scope.row.maxNum}}
                                </template>
                            </el-table-column>
                            <el-table-column key="53" align="center"  label="已预约" >
                                <template slot-scope="scope">{{scope.row.num}}</template> 
                            </el-table-column>
                    </el-table-column>
                </el-table>
                <!-- w6 -->
                <el-table
                    v-if="((!isEdit)&&((data6!=null)&&(tableTopList!=null)))"
                    :data='data6'
                    style="width: 50%"
                    :cell-class-name="cellStyle"
                    :header-cell-class-name="tableHeaderStyle">
                    <el-table-column key="60"  prop="workTimeStart" label="开始时间" align="center" width="100"></el-table-column>
                    <el-table-column key="61" prop="workTimeEnd" label="结束时间" align="center" width="100"></el-table-column> 
                    <el-table-column key="62" align="center" :label="tableTopList[5].date+'('+tableTopList[5].week+')'">
                            <el-table-column key="63" align="center" label="总号量" >
                                <template slot-scope="scope">
                                    {{scope.row.maxNum}}
                                </template>
                            </el-table-column>
                            <el-table-column key="64" align="center"  label="已预约" >
                                <template slot-scope="scope">{{scope.row.num}}</template> 
                            </el-table-column>
                    </el-table-column>
                </el-table>
            </div>
                <!-- w7 -->
                <el-table
                    v-if="((!isEdit)&&((data7!=null)&&(tableTopList!=null)))"
                    :data='data7'
                    style="width: 50%"
                    :cell-class-name="cellStyle"
                    :header-cell-class-name="tableHeaderStyle">
                    <el-table-column key="70"  prop="workTimeStart" label="开始时间" align="center" width="100"></el-table-column>
                    <el-table-column key="71" prop="workTimeEnd" label="结束时间" align="center" width="100"></el-table-column> 
                    <el-table-column key="72" align="center" :label="tableTopList[6].date+'('+tableTopList[6].week+')'">
                            <el-table-column key="73" align="center" label="总号量" >
                                <template slot-scope="scope">
                                    {{scope.row.maxNum}}
                                </template>
                            </el-table-column>
                            <el-table-column key="74" align="center"  label="已预约" >
                                <template slot-scope="scope">{{scope.row.num}}</template> 
                            </el-table-column>
                    </el-table-column>
                </el-table>

                <!-- 编辑表格 -->
                <div style="display:flex">
                <!-- w1 -->
                <el-table
                    v-if="((isEdit)&&((data1!=null)&&(tableTopList!=null)))"
                    :data='data1'
                    style="width: 50%"
                    :cell-class-name="cellStyle"
                    :header-cell-class-name="tableHeaderStyle">
                    <el-table-column key="a0"  prop="workTimeStart" label="开始时间" align="center" width="100"></el-table-column>
                    <el-table-column key="a1" prop="workTimeEnd" label="结束时间" align="center" width="100"></el-table-column> 
                    <el-table-column key="a2" align="center" :label="tableTopList[0].date+'('+tableTopList[0].week+')'">
                            <el-table-column key="a3" align="center" label="总号量" >
                                <template slot-scope="scope">
                                    <div class="bosf">
                                        <el-input-number style="width:100px;height:32px" v-model="scope.row.maxNum" controls-position="right" @change="handleChange(scope.row)" :min="0" :max="65535"></el-input-number>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column key="a4" align="center"  label="已预约" >
                                <template slot-scope="scope">
                                        <span style="display:block;height:40px">{{scope.row.num}}</span>
                                </template>
                            </el-table-column>
                    </el-table-column>
                </el-table>
                <!-- w2 -->
                <el-table
                    v-if="((isEdit)&&((data2!=null)&&(tableTopList!=null)))"
                    :data='data2'
                    style="width: 50%"
                    :cell-class-name="cellStyle"
                    :header-cell-class-name="tableHeaderStyle">
                    <el-table-column key="b1"  prop="workTimeStart" label="开始时间" align="center" width="100"></el-table-column>
                    <el-table-column key="b2" prop="workTimeEnd" label="结束时间" align="center" width="100"></el-table-column> 
                    <el-table-column align="center" :label="tableTopList[1].date+'('+tableTopList[1].week+')'">
                            <el-table-column key="b3" align="center" label="总号量" >
                                <template slot-scope="scope">
                                <div class="bosf">
                                    <el-input-number style="width:100px;height:32px" v-model="scope.row.maxNum" controls-position="right" @change="handleChange(scope.row)" :min="0" :max="65535"></el-input-number>
                                </div>
                                </template>
                            </el-table-column>
                            <el-table-column key="b4" align="center"  label="已预约" >
                                <template slot-scope="scope">
                                    <span style="display:block;height:40px">{{scope.row.num}}</span>
                                </template>
                            </el-table-column>
                    </el-table-column>
                </el-table>
            </div>
                <!-- w3 -->
                <div style="display:flex">
                <el-table
                    v-if="((isEdit)&&((data3!=null)&&(tableTopList!=null)))"
                    :data='data3'
                    style="width: 100%"
                    :cell-class-name="cellStyle"
                    :header-cell-class-name="tableHeaderStyle">
                    <el-table-column key="c0"  prop="workTimeStart" label="开始时间" align="center" width="100"></el-table-column>
                    <el-table-column key="c1" prop="workTimeEnd" label="结束时间" align="center" width="100"></el-table-column> 
                    <el-table-column key="c2" align="center" :label="tableTopList[2].date+'('+tableTopList[2].week+')'">
                            <el-table-column key="c3"  align="center" label="总号量" >
                                <template slot-scope="scope">
                                <div class="bosf">
                                    <el-input-number style="width:100px;height:32px" v-model="scope.row.maxNum" controls-position="right" @change="handleChange(scope.row)" :min="0" :max="65535"></el-input-number>
                                </div>
                                </template>
                            </el-table-column>
                            <el-table-column key="c4" align="center"  label="已预约" >
                                <template slot-scope="scope">
                                    <span style="display:block;height:40px">{{scope.row.num}}</span>
                                </template>
                            </el-table-column>
                    </el-table-column>
                </el-table>
                <!-- w4 -->
                <el-table
                    v-if="((isEdit)&&((data4!=null)&&(tableTopList!=null)))"
                    :data='data4'
                    style="width: 50%"
                    :cell-class-name="cellStyle"
                    :header-cell-class-name="tableHeaderStyle">
                    <el-table-column key="d0"  prop="workTimeStart" label="开始时间" align="center" width="100"></el-table-column>
                    <el-table-column key="d1" prop="workTimeEnd" label="结束时间" align="center" width="100"></el-table-column> 
                    <el-table-column key="d2" align="center" :label="tableTopList[3].date+'('+tableTopList[3].week+')'">
                            <el-table-column key="d3" align="center" label="总号量" >
                                <template slot-scope="scope">
                                <div class="bosf">
                                    <el-input-number style="width:100px;height:32px" v-model="scope.row.maxNum" controls-position="right" @change="handleChange(scope.row)" :min="0" :max="65535"></el-input-number>
                                </div>
                                </template>
                            </el-table-column>
                            <el-table-column key="d4" align="center"  label="已预约" >
                                <template slot-scope="scope">
                                    <span style="display:block;height:40px">{{scope.row.num}}</span>
                                </template>
                            </el-table-column>
                    </el-table-column>
                </el-table>
            </div>
            <div style="display:flex">
                <!-- w5 -->
                <el-table
                    v-if="((isEdit)&&((data5!=null)&&(tableTopList!=null)))"
                    :data='data5'
                    style="width: 50%"
                    :cell-class-name="cellStyle"
                    :header-cell-class-name="tableHeaderStyle">
                    <el-table-column key="e0"  prop="workTimeStart" label="开始时间" align="center" width="100"></el-table-column>
                    <el-table-column key="e1" prop="workTimeEnd" label="结束时间" align="center" width="100"></el-table-column> 
                    <el-table-column key="e2" align="center" :label="tableTopList[4].date+'('+tableTopList[4].week+')'">
                            <el-table-column key="e3" align="center" label="总号量" >
                                <template slot-scope="scope">
                                <div class="bosf">
                                    <el-input-number style="width:100px;height:32px" v-model="scope.row.maxNum" controls-position="right" @change="handleChange(scope.row)" :min="0" :max="65535"></el-input-number>
                                </div>
                                </template>
                            </el-table-column>
                            <el-table-column key="e4" align="center"  label="已预约" >
                                <template slot-scope="scope">
                                    <span style="display:block;height:40px">{{scope.row.num}}</span>
                                </template> 
                            </el-table-column>
                    </el-table-column>
                </el-table>
                <!-- w6 -->
                <el-table
                    v-if="((isEdit)&&((data6!=null)&&(tableTopList!=null)))"
                    :data='data6'
                    style="width: 50%"
                    :cell-class-name="cellStyle"
                    :header-cell-class-name="tableHeaderStyle">
                    <el-table-column key="f0"  prop="workTimeStart" label="开始时间" align="center" width="100"></el-table-column>
                    <el-table-column key="f1" prop="workTimeEnd" label="结束时间" align="center" width="100"></el-table-column> 
                    <el-table-column key="f2" align="center" :label="tableTopList[5].date+'('+tableTopList[5].week+')'">
                            <el-table-column key="f3" align="center" label="总号量" >
                                <template slot-scope="scope">
                                <div class="bosf">
                                    <el-input-number style="width:100px;height:32px" v-model="scope.row.maxNum" controls-position="right" @change="handleChange(scope.row)" :min="0" :max="65535"></el-input-number>
                                </div>
                                </template>
                            </el-table-column>
                            <el-table-column key="f4" align="center"  label="已预约" >
                                <template slot-scope="scope">
                                    <span style="display:block;height:40px">{{scope.row.num}}</span>
                                </template>
                            </el-table-column>
                    </el-table-column>
                </el-table>
            </div>
                <!-- w7 -->
                <el-table
                    v-if="((isEdit)&&((data7!=null)&&(tableTopList!=null)))"
                    :data='data7'
                    style="width: 50%"
                    :cell-class-name="cellStyle"
                    :header-cell-class-name="tableHeaderStyle">
                    <el-table-column key="g0"  prop="workTimeStart" label="开始时间" align="center" width="100"></el-table-column>
                    <el-table-column key="g1" prop="workTimeEnd" label="结束时间" align="center" width="100"></el-table-column> 
                    <el-table-column key="g2" align="center" :label="tableTopList[6].date+'('+tableTopList[6].week+')'">
                            <el-table-column key="g3" align="center" label="总号量" >
                                <template slot-scope="scope">
                                <div class="bosf">
                                    <el-input-number style="width:100px;height:32px" v-model="scope.row.maxNum" controls-position="right" @change="handleChange(scope.row)" :min="0" :max="65535"></el-input-number>
                                </div>
                                </template>
                            </el-table-column>
                            <el-table-column key="g4" align="center"  label="已预约" >
                                <template slot-scope="scope">
                                    <span style="display:block;height:40px">{{scope.row.num}}</span>
                                </template>
                            </el-table-column>
                    </el-table-column>
                </el-table>
                
        <!-- ------------------------------------------------- -->
            
        </div>
        <!-- 空 -->
        <div class="box-emprty" v-if="isEmperty">
            <div class="emptyBox">
                    <img class="emimg" :src="emptyImg"/>
                    <span class="tip-text">当前科室暂无预约数据！</span>
                    <!-- <span class="tip-text">零动医疗科技有限公司相关人员。</span> -->
            </div>
        </div>
    </div>
</template>

<script>
import {getSchduList,changeMaxNum} from '@/api/order.js'

export default {
    data(){
        
        const weekMap = new Map([['1','周一'],['2','周二'],['3','周三'],['4','周四'],['5','周五'],['6','周六'],['7','周日']])
        return{
            isEmperty:null,
            emptyImg:'https://sz.drbodyhealth.com/emptybox.png',
            id:null,
            weekMap,
            isEdit:false,
            tableTopList:null, //表头列表
            data1:null,
            data2:null,
            data3:null,
            data4:null,
            data5:null,
            data6:null,
            data7:null,
            //是否上周
            lastWeek:false,
            nowWeek:true,
            nextWeek:false,
            offset:0,
            pre:0,
            next:0,
            preIndex:0,
            nextIndex:0,
           
        }
    },

    methods:{
        //表头样式回调
        tableHeaderStyle(rowindex){
            if((rowindex.column.label == "总号量") || (rowindex.column.label == "已预约")){
                return 'tableStyle1';
            }else{
                return 'tableStyle2'
            }
        },
        cellStyle(index){
            if((index.column.label=="结束时间") || (index.column.label=="已预约")){
                return 'cellStyle1'
            }else{
               return 'cellStyle2'
            }
        },
        //+  - 
        handleChange(e){
            //console.log(e.id);
            //后台逻辑
            changeMaxNum({
                id:e.id,
                maxNum:e.maxNum
            }).then(res=>{
                if(res.data.status==200){
                    this.$message({
                        message: '修改成功！',
                        type: 'success',
                        duration:400
                        });
                }else{
                    this.$message.error(res.data.message);
                }
            })
        },
        //确认修改
        xiugaiN(){
            this.isEdit = false;
            this.getDataList(this.$parent.firtMenuId);//offset待定
        },
        //修改
        xiugaiC(){
            this.isEdit = true;
        },
        //获取预约列表
        getDataList(id,offset){
            this.clear();//清空  否则表格数据乱
            getSchduList({
                wid: id,
                offset:offset
            }).then(res=>{
                if(res.data.status == 200){
                    var tempData =res.data.data;
                    //console.log("tableData2",tempData);
                    //父级是否为空判断
                    if(tempData.length==0){
                        this.isEmperty=true;
                    }
                    if(tempData.length>0){
                        this.isEmperty=false;
                        var tem_arry=[];//表头数据
                        tempData.forEach((item,index)=>{
                        var tem_week=this.weekMap.get(item.weekDay);
                        var tem_date = (item.workTime).substring(5);
                        var temp_record = item.workdayPeriodVOS;
                        var tem_obj = {week:tem_week,date:tem_date};
                        tem_arry.push(tem_obj);

                        if(index==0){
                            this.data1 = temp_record;
                        }
                        if(index==1){
                            this.data2 = temp_record;
                        }
                        if(index==2){
                            this.data3 = temp_record;
                        }
                        if(index==3){
                            this.data4 = temp_record;
                        }
                        if(index==4){
                            this.data5 = temp_record;
                        }
                        if(index==5){
                            this.data6 = temp_record;
                        }
                        if(index==6){
                            this.data7 = temp_record;
                        }
                    
                        })
                        this.tableTopList = tem_arry;
                        }
                        
                }else{
                    this.$message.error(res.data.message);
                    this.isEmperty=false;
                }
                    
            }).catch(res=>{
                this.$message.error(res);
              })
        },
        //clear
        clear(){
            this.tableTopList = null;
            this.data1=null;
            this.data2=null;
            this.data3=null;
            this.data4=null;
            this.data5=null;
            this.data6=null;
            this.data7=null;
        },
        //周切换
        activel(){//上一周
            this.lastWeek = true;
            this.nowWeek = false;
            this.nextWeek = false;
            this.nextIndex = 0;
            this.preIndex = this.preIndex+1;
            var tempOffset = this.preIndex*7;
            this.getDataList(this.$parent.firtMenuId,this.next-tempOffset);
            this.pre =  this.next-tempOffset;
        },
        activeno(){
            this.lastWeek = false;
            this.nowWeek = true;
            this.nextWeek = false;
            this.getDataList(this.$parent.firtMenuId,0);
        },
        activen(){
            this.lastWeek = false;
            this.nowWeek = false;
            this.nextWeek = true;
            this.preIndex = 0;
            this.nextIndex = this.nextIndex+1;
            var tempOffset2 = this.nextIndex*7;
            this.getDataList(this.$parent.firtMenuId,this.pre+tempOffset2);
            this.next = this.pre+tempOffset2;
        }
    
    }
}
</script>

<style scoped lang="stylus">
/deep/.tableStyle1{
    color:rgba(0, 204, 204, 1) !important;
    background-color: rgba(206, 248, 248, 1) !important;
}

/deep/.tableStyle2{
    color:#FFFFFF !important;
    background-color:#00CCCC !important;
}

/deep/.cellStyle1{
    color:#333333 !important;
    background-color:#F3F5F6 !important;
}
/deep/.cellStyle2{
    color:#333333 !important;
    background-color:#FFFFFF !important;
}

/*无底部边框 */
/deep/.el-table td.el-table__cell{
    border-bottom: none !important;
}
/deep/.el-input-number__decrease{
    bottom: -7px !important;
}
/*common-style */
.active-box
    width: 100px 
    height: 30px 
    cursor pointer
    background-color: #F4FBFE
    font-size: 16px 
    border-radius: 2px 
    border: 1px solid #00cccc 
    margin-right: 20px 
    text-align: center
    line-height: 30px 
.active-box-active
    width: 100px 
    height: 30px 
    cursor pointer
    color:#fff
    background-color: #00CCCC
    font-size: 16px 
    border-radius: 2px 
    margin-right: 20px 
    text-align: center
    line-height: 30px 

/*编辑表格样式 */
.bosf
    height: 42px 
    margin-right: 20px
.box-recove
    overflow hidden
    .box-top
        display: flex
        justify-content: space-between
        .box-top-left
            display: flex
    .table-box
        width: 100%
        min-height: 100vh
        margin-top: 16px  
/* */
.box-emprty
    width: 100%
    height: 100%
    display: flex
    flex-direction: columen
    align-items: center
    .emptyBox
        width:310px 
        height:300px  
        margin:140px auto
        text-align: center
        .emimg
            width: 260px 
            height: 250px 
        .tip-text
            display: block
            font-size:16px 
            color:#333333
        
        
        

</style>