<template>
    <div class="checkTask">
      <!-- top -->
      <el-breadcrumb separator="/">
          <el-breadcrumb-item>排班管理</el-breadcrumb-item>
          <el-breadcrumb-item>排班情况</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="blueTitle" style="margin-left:12px;">排班情况</div>
      <!-- 排班管理comon -->
      <div class="mainbox">
        <div class="left-box">
             <div class="blueTitle" style="margin-top:0px !important;">科室结构</div>
             <!-- menu -->
             <el-menu v-if="menuList"  unique-opened :collapse-transition="false" @select="select"  active-text-color="#00cccc" text-color="red" :default-active="menuList[0].id"> 
                <el-menu-item :index="item.id+''" v-for="(item,index) in menuList" :key="index">
                    <!-- <i class="el-icon-menu"></i> -->
                    <span slot="title">{{item.deptName}}</span>
                    <!-- <el-menu-item-group>
                      <template slot="title">分组一</template>
                        <el-menu-item index="1-1">选项1</el-menu-item>
                        <el-menu-item index="1-2">选项2</el-menu-item>
                    </el-menu-item-group> -->
                </el-menu-item>
            </el-menu> 

        </div>
        <div class="right-box">
            <NothingTemplate v-if="isEmpty==10"/>
            <SchTemplate ref="child" v-if="isEmpty==false"/>
            <EmptyTemplate v-if="isEmpty==true"/>
        </div>
      </div>
            
    </div>
</template>


<script>
import {getHospitalList,getRoomList,getSchduList} from '@/api/order.js'
import SchTemplate from '@/views/scheduling/scheduTemplate.vue'
import EmptyTemplate from '@/views/scheduling/emptyTemplate.vue'
import NothingTemplate from '@/views/scheduling/nothingTemplate.vue'
export default {
  components:{
    SchTemplate,
    EmptyTemplate,
    NothingTemplate
    },
   
    data(){
        return{
          hospitalId:null,
          hospitalName:'',
          menuList:null,
          firtMenuId:null,
          isEmpty:10,
        }
    },

    mounted(){
      this.getHoList();
    },

    methods:{
      //查询医院列表
      getHoList(){
            getHospitalList({
                //当前该账户下以默认绑定有的
              }).then(res=>{
                if(res.data.status==200){
                    this.hospitalId = res.data.data.id;
                    this.hospitalName = res.data.data.orgName;
                    //开始查询科室列表
                    getRoomList({
                        orgId:this.hospitalId
                      }).then(res=>{
                        if(res.data.status ==200){
                            //console.log(res.data.data.records);
                            //console.log("长度",res.data.data.records.length)
                            if(res.data.data.records.length==0){
                              this.isEmpty=true;
                            }else{
                              this.isEmpty=false;
                              this.menuList = res.data.data.records;
                              //得到默认显示一个id
                              this.firtMenuId = res.data.data.records[0].id;
                              this.$nextTick(()=>{
                                this.$refs.child.getDataList(this.firtMenuId);//加载默认数据
                              })
                            }
                          }else{
                            this.isEmpty=true
                            this.$message.error(res.data.message);
                          }
                    }).catch(res=>{
                        this.$message.error(res);
                    })
                }else{
                    this.$message.error(res.data.message);
                }
            })
        },
        select(e){
            //console.log("e",e);
            this.firtMenuId = e;//当前科室id
            this.$nextTick(()=>{
                  this.$refs.child.getDataList(e);//加载默认数据
                })
        },

    }
}
</script>
<style scoped lang="stylus">
//change elemen-ui style
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
  font-weight: bold !important;
}
/deep/.el-menu{
    background-color: #ECF9FF !important;
    width: 100% !important;
}
/deep/.el-submenu__title{
    padding-left: 10px !important;
    color: #333333 !important;
}
/deep/.el-menu-item{
    min-width: 100px !important;
    padding: 0px !important;
    line-height: 40px !important;
    text-align: center !important;
    color: #333333 !important;
}
/deep/.el-menu-item:visited{
  background-color: red !important;
  color: red !important;
}
/deep/.el-menu-item:hover{
  background-color: #ECF9FF !important;
  color: #00cccc !important;

}


//component style
.blueTitle
  font-size: 18px 
  color: #353535
  display: flex
  align-items: center 
  margin: 36px 20px
  position: relative
  &::before
    width: 3px
    height: 18px
    content: ""
    position: absolute
    border-radius: .03rem
    left: -10px
    bottom:1px
    background-color: #00CCCC


//normal style
.checkTask
    overflow hidden
    margin-top: 0px
    .mainbox
        width: 100%
        display: flex
        .left-box
            width: 12%
            min-height: 100vh
            background-color: #ECF9FF
            box-sizing: border-box
            padding: 24px 0px 24px 0px
        .right-box
            width: 88%
            background-color: #F4FBFE
            box-sizing: border-box
            padding: 30px 40px
            min-height: 100vh
   
               

            
</style>