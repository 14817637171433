<!-- 康复科--- 运动康复 -->
<template>
    <div style="position:relative;z-index: 1;">
    <div class="box-emprty">
        <div class="emptyBox">
               
        </div>
    </div>
</div>
</template>

<script>

export default {
    components:{},
    data(){
        return{
          
        }
    },
    created(){
        if(this.$route.query){
           
        }
        
    },
  
   
}
</script>

<style scoped lang="stylus">
.box-emprty
    width: 100%
    height: 100%
    display: flex
    flex-direction: columen
    align-items: center
    .emptyBox
        width:310px 
        height:300px  
        margin:140px auto
        text-align: center
        

</style>